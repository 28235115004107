.live-chat {
  position: fixed;
  width: 364px;
  height: 100%;
  right: 0px;
  top: 0px;
  background: #ffffff;

  &.greetings-chat {
    display: flex;
    align-items: center;
    text-align: center;
    > div {
      width: 80%;
      text-align: left;
      margin: auto;
      > .welcome-header {
        color: #0d0557;
        font-size: 24px;
        margin-bottom: 24px;
      }

      > .welcome-header > :not(.description) {
        font-weight: 500;
      }

      > .welcome-header > .description {
        color: #8b8998;
        font-size: 16px;
        margin-top: 12px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .live-chat {
    // position: relative;
    width: 100%;
    top: unset;
    bottom: 0px;
  }
}

input {
  border: none;
  background: #ffffff;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 20px;
  width: 100%;
  margin-bottom: 32px;
  font-family: "muliregular";
  font-size: 14px;
  &:active,
  &:focus {
    outline: 0px;
    outline-offset: 0px;
  }
  &::placeholder {
    color: #8b8998;
    font-family: "muliregular" !important;
  }
}

button.join-chat {
  font-size: 16px;
  color: #ffffff;
  font-family: "muliregular";
  border: none;
  border-radius: 100px;
  width: 100%;
  font-size: 16px;
  height: 48px;
  &:disabled {
    background: #d4d3df;
  }
  &:not(:disabled) {
    background: #00c670;
  }
}

.str-chat__ul {
  margin-top: 29px;
}

.str-chat.str-chat-channel {
  height: -webkit-fill-available !important;
}

.str-chat__send-button {
  width: 40px !important;
  height: 36.99px !important;
  padding: 0px !important;
  border-radius: 10px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  > svg > path {
    fill: #ffffff;
  }
}

.str-chat__message-reactions-list-item > .latest-user {
  display: none;
}

.str-chat__main-panel {
  padding: 0px !important;
}

.str-chat__textarea textarea {
  background: #f7f6ff !important;
  border: none !important;
  font-family: "muliregular";
  &::placeholder {
    color: #8b8998;
    font-family: "muliregular";
  }
}

.str-chat__small-message-input-fileupload {
  display: flex;
  align-items: center;
}

.rfu-file-upload-button {
  padding-top: 2px;
}

.str-chat__message-team--editing {
  background: #ffffff !important;
  border: none;
  margin-left: -10px;
  margin-right: -10px;
  box-shadow: 0px 0px 10px rgba(13, 5, 87, 0.1) !important;

  .rta__textarea.str-chat__textarea__textarea {
    background: #f7f6ff !important;
    border-radius: 20px !important;
    box-shadow: inset 0 0 0 1px #0d0557 !important;
  }

  button {
    &[type="submit"] {
      color: #00c670 !important;
    }

    font-family: "muliregular";
  }
}

.str-chat__send-button,
.str-chat__message-replies-count-button {
  color: #00c670 !important;
  font-family: "muliregular";
  > svg > path {
    fill: #00c670 !important;
  }
}

.str-chat__input-fileupload {
  > svg {
    margin-top: 2px;
  }
}

.str-chat__message-livestream-author {
  strong {
    color: #0d0557;
    font-family: "mulibold";
    font-size: 16px;
  }
}

.str-chat__avatar.str-chat__avatar--circle {
  width: 32px !important;
  height: 32px !important;
  margin-top: 8px;
}

.str-chat__message-livestream-content {
  p {
    font-family: "muliregular";
    font-size: 14px;
    color: #1b1b1b;
  }
}

.str-chat__container {
  position: absolute;
  width: 100%;
}

.str-chat__li {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.str-chat__avatar-fallback {
  background-color: #00c670 !important;
  border-radius: 100%;
}

.str-chat__list {
  padding-bottom: 60px !important;
  max-height: 100%;
  height: 100vh;
  overflow-y: scroll !important;
}

.str-chat__small-message-input__wrapper {
  background: #ffffff;
  position: absolute;
  bottom: 0px;
  height: 60px !important;
  box-shadow: 0px 0px 10px rgba(13, 5, 87, 0.1) !important;
}

.str-chat__message-livestream {
  .str-chat__message-livestream-right {
    background: #f8f8fc;
    margin: 0px 10px;
    padding: 2px;
    border-radius: 8px;
    transition: 1s background;
  }
  &:hover {
    .str-chat__message-livestream-right {
      background: transparent;
    }
  }
}
